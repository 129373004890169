<template>
  <ion-page>
    <Header type="0" :title="$route.query.id ?'任务详情' : '创建任务'" v-if="menuRole == 'phone'"> </Header>
    <ion-content scroll-y="false" id="ProjectMainProjectListOut">
      <div class="root">
        <div class="item" v-if="formData.type == 2">
          <div class="left"><span class="required">*</span>创建人</div>
          <div class="right">
            <ion-text>{{ formData.creator }}</ion-text>
          </div>
        </div>
        <div class="item">
          <div class="item remark">
            <ion-text class="left">
              <span class="required">*</span>任务描述</ion-text
            >
            <div style="max-height: 150px; width: 100%; overflow: auto">
              <ion-textarea
                auto-grow="true"
                class="right"
                placeholder="请输入您的任务描述..."
                maxlength="500"
                v-model="formData.taskDesc"
                :disabled="formData.type == 2"
              ></ion-textarea>
            </div>
          </div>
        </div>
        <div
          class="item"
          @click="openChooseOrganizationModal"
          v-if="formData.type == 1"
        >
          <div class="left"><span class="required">*</span>待办人</div>
          <div class="right">
            <ion-text>{{ formData.executor }}</ion-text>
            <span class="iconfont icon-jinru"></span>
          </div>
        </div>
        <div class="item" v-if="formData.type == 2">
          <div class="left"><span class="required">*</span>待办人</div>
          <div class="right">
            <ion-text>{{ formData.executor }}</ion-text>
          </div>
        </div>
        <!-- <div class="item">
          <div class="left"><span class="required">*</span>状态</div>
          <div class="right">
            <div style="margin-right: 20px">
                <input type="radio" value="2" v-model="formData.type" /><span class="checkboxText">已完成</span>
            </div>
            <div>
               <input type="radio" value="1" v-model="formData.type" /><span class="checkboxText">未完成</span>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup> -->
    </ion-content>
    <person-choose @chooseCode="searchPerson"></person-choose>
    <ion-footer>
      <ion-button
        expand="block"
        @click="commit"
        v-if="formData.type == 1 || formData.type == 2"
        :hidden="formData.stateId == 3"
        >提交</ion-button
      >
    </ion-footer>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import { createTask, sendTask, getTask } from "@/api/ddTask/taskApi";
import personChoose from "./personChoose.vue";
import { menuController, loadingController, alertController } from "@ionic/vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils.ts";
import * as dd from "dingtalk-jsapi";
export default {
  name: "createTask",
  components: {
    Header,
    personChoose,
  },
  data() {
    return {
      showPicker: false,
      menuRole: "phone",
      formData: {
        type: 1,
        taskDesc: "",
      },
    };
  },
  mounted() {
    this.menuRole = localStorage.getItem("menuRole");
    var _this = this;
    console.log(_this.$route.query.id)
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: _this.$route.query.id ? "任务详情" : "创建任务", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    console.log(this.$route.query, 12312);
    if (this.$route.query.id) {
      this.getTask(this.$route.query.id);
    } else {
      this.createTask();
    }
  },
  methods: {
    onConfirm(e) {
      console.log(e.id);
      this.showPicker = false;
    },
    createTask() {
      createTask().then((res) => {
        this.formData.id = res.data.data.id;
      });
    },
    openChooseOrganizationModal() {
      menuController.enable(true, "searchPerson");
      menuController.open("searchPerson");
    },
    getTask(id) {
      getTask({ id: id }).then((res) => {
        if (!res.data.isError && res.data.data.isSuccess) {
          this.formData.taskDesc = res.data.data.resultData.taskDesc || "";
          this.formData.id = id;
          this.formData.creator = res.data.data.resultData.creator || "";
          this.formData.type = 2;
          this.formData.stateId = res.data.data.resultData.stateId || "";
          this.formData.executor = res.data.data.resultData.executor || "";
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    //选择申请人
    searchPerson(val) {
      this.formData.executorId = val.id;
      this.formData.executor = val.nameCN;
    },
    // 提交
    async commit() {
      const _this = this;
      var sendData = {};
      if (_this.$route.query.id) {
        sendData.id = _this.formData.id;
        sendData.userId = SystemUtils.loginUser.id;
        sendData.nameCN = SystemUtils.loginUser.name;
        sendData.type = _this.formData.type;
      } else {
        sendData = _this.formData;
        sendData.taskDesc = sendData.taskDesc.trim();
        if (!sendData.taskDesc) {
          Utils.presentToastTopWarning("请输入任务描述", "danger");
          return false;
        } else if (!sendData.executor) {
          Utils.presentToastTopWarning("请选择代办人", "danger");
          return false;
        }
      }
      console.log();

      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });

              await loading.present();

              sendTask(Object.assign({},sendData,{agentId:localStorage.getItem('agentId')})).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  Utils.presentToastTopWarning("提交成功", "success");
                  _this.$router.go(-1);
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
  },
};
</script>
<style lang="scss" scoped>
.checkboxText {
  vertical-align: middle;
  white-space: nowrap;
}
input {
  vertical-align: middle;
}
ion-button {
  --background: #00529c;
}

ion-checkbox {
  vertical-align: middle;
}
.root .remark .right {
  width: 100%;
}
</style>